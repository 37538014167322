*, *::before, *::after {
    box-sizing: border-box;
}
* {
    margin: 0;
}
html, body {
    height: 100%;
}
body {
    line-height: 1;
}
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
input, button, textarea, select {
    font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
a, a:hover, a:active{
    color: inherit;
    text-decoration: none;
}
input, textarea{
    outline: none;
}
button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}
#root, #__next {
    isolation: isolate;
}