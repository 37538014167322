@import "reset.scss";
@import "container.scss";
@import "mixins.scss";

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500|Open+Sans:300,400,600,700&subset=cyrillic');

@function rem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

body {
    font-family: 'Open Sans', sans-serif;
}

/* wrapper */

.wrapper {
    display: flex;

    @media screen and (max-width: 767.98px) {
        flex-flow: row wrap;
    }
}

/* main */

.about {
    box-sizing: content-box;
    padding: 50px 5%;
    width: 60%;

    @media screen and (max-width: 767.98px) {
        width: 100%;
    }
}
.about-title {
    font-family: 'Montserrat', sans-serif;
    font-size: rem(40px);
    font-weight: 300;
    margin-bottom: rem(30px);
}
.about-description {
    margin-bottom: 50px;
}
.about-ttl {
    background: linear-gradient(to right, rgba(45,169,121,1) 0%,rgba(113,199,103,1) 100%);
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: rem(30px);
    margin-bottom: 30px;
    padding: 0 rem(25px);
    text-transform: uppercase;
    width: max-content;
}
.about-container > .checkbox {
    margin-bottom: 30px;
}
.about-container > .content {
    margin-bottom: 30px;
}

/* checkbox */

.checkbox {
    display: block;
    padding: 10px 0 9px;
    position: relative;
    user-select: none;
    width: max-content;
}
.checkbox input {
    display: none;
}
.checkbox span {
    cursor: pointer;
    padding-left: 80px;
}
.checkbox span:before {
    background: #ce1919;
    border-radius: 50px;
    content: '';
    display: block;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    transition: ease 300ms;
    width: 60px;
}
.checkbox span:after {
    background: #fff;
    border-radius: 50%;
    content: '';
    height: 29px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: ease 300ms;
    width: 29px;
}
.checkbox input:checked ~ span:after {
    left: 27px;
}
.checkbox input:checked ~ span:before {
    background: rgba(45,169,121,1);
}

/* content */

.content {
    font-size: 16px;
    line-height: 1.8;
}
.content p {
    margin-bottom: 15px;
}
.content a {
    color: #009358;
    border-bottom: 1px dashed;
    transition: ease 300ms;
}
.content a:hover {
    border-color: transparent;
}

/* works */

.works-enum {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: rem(30px);
    margin-top: rem(50px);

    @media screen and (max-width: 767.98px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: rem(20px);
    }
}
a.works-item,
.works-item {
    box-shadow: 0 0 10px rgba(0,0,0,.07);
    border-radius: 3px;
    display: block;
    overflow: hidden;
    padding: 7px;
    position: relative;

    span {
        color: #fff;
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        opacity: 0;
        transition: ease-in 300ms;
        transform: translate(-50%,-50%);
        position: absolute;
        top: 50%;
        left: 50%;
    }

    img {
        display: block;
        width: 100%;
    }
}
.works-item:hover span{
    opacity: 1;
}
.works-item:before {
    background: #008c59;
    content: '';
    height: 100%;
    opacity: .8;
    padding: 50px 50px;
    position: absolute;
    top: 0;
    left: 150%;
    transform: skewX(15deg);
    transition: ease 400ms;
    width: 130%;
}
.works-item:hover:before {
    left: -15%;
}

/* side */

.side {
    background: linear-gradient(135.84deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 71.45%), #F1F1F1;
    border-left: 6px solid #FFFFFF;
    box-shadow: 0px 10px 20px rgba(44,81,123,.1);
    color: #333;
    padding: 50px 5%;
    position: relative;
    min-width: 340px;
    width: 30%;

    @media screen and (max-width: 767.98px) {
        min-width: unset;
        width: 100%;
    }
}

/* ttl */

.ttl {
    font-size: rem(17px);
    font-weight: 700;
    letter-spacing: rem(1px);
    padding-bottom: rem(30px);
    text-transform: uppercase;
}

/* skills */

.skills-group {
    margin-bottom: rem(30px);
}
.skills-name {
    color: #333;
    font-size: rem(13px);
    font-weight: 400;
    padding-bottom: rem(15px);
    letter-spacing: rem(1px);
    text-transform: uppercase;
}
.skills-progress {
    background-color: #fff;
    border-radius: 2px;
    overflow: hidden;
}
.skills-progress-value {
    background: linear-gradient(to right, rgba(45,169,121,1) 0%,rgba(113,199,103,1) 100%);
    color: #fff;
    font-size: rem(13px);
    font-weight: 300;
    display: block;
    line-height: rem(30px);
    height: rem(30px);
    padding-left: rem(15px);
    text-transform: uppercase;
}
.skills-item {
    margin-bottom: rem(17px);
}

/* avatar */

.avatar {
    box-shadow: 0 3px 15px rgba(72,72,72,.38);
    border-radius: 50%;
    height: 220px;
    margin: 0 auto 50px;
    overflow: hidden;
    width: 220px;

    @media screen and (max-width: 767.98px) {
        display: none;
    }
}

/* contacts */

.contacts {
    margin-top: rem(80px);
}
.contacts-item {
    display: flex;
    font-weight: 300;
    margin-bottom: rem(20px);
    line-height: 1.2;
}
.contacts-name {
    font-weight: 500;
    padding-right: rem(7px);
}
.contacts-value {

    a {
        color: inherit;
        border-bottom: 1px dashed #009358;
        transition: ease 300ms;

        &:hover {
            border-color: transparent;
        }
    }
}